import React from "react"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import useGallery from "../utils/useGallery"

import "../styles/gallery.scss"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Gallery({ pageContext }) {
  const images = useGallery()

  return (
    <div className="gallery-page">
      <Layout pageContext={pageContext}>
        <div className="page-banner banner">
          <div className={`page-banner-content ${pageContext.language}`}>
            <h1>
              <FormattedMessage id="global_gallery" />
            </h1>
          </div>
        </div>
        <div className="imgs-container">
          {images.map(({ id, gatsbyImageData }) => (
            <GatsbyImage key={id} image={gatsbyImageData} alt={id} />
          ))}
        </div>
      </Layout>
    </div>
  )
}

export { Head } from "../components/Head"
